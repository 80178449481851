import Login from './pages/Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './assets/scss/theme.scss';
import './styles.css';
import Dashboard from './pages/dashboard/Dashboard';
import { PermissionsProvider } from './context/PermissionsContext';

const App = () => {
  return (
    <BrowserRouter>
      <PermissionsProvider>
        <Routes>
          <Route exact path='/' element={<Login />} />
          <Route exact path='/dashboard' element={<Dashboard />} />
        </Routes>
      </PermissionsProvider>
    </BrowserRouter>
  )
}

export default App;