import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import profile from "../../assets/images/logo-maxim.png";
import avatar from '../../assets/images/avatar.png';
import { getItem, clearSession } from '../../utils';
import moment from 'moment';
import 'moment/locale/es';
import { useNavigate } from 'react-router-dom';
import CardComponent from '../../components/CardComponent';
import CardProfile from './CardProfile';
import { usePermissions } from '../../context/PermissionsContext';

moment.locale('es');

const Dashboard = () => {
  let navigate = useNavigate();
  const name = getItem('fullName');
  const email = getItem('email');
  const lastLoginDate = getItem('lastLogin');
  const lastLoginOrigin = moment.utc(getItem('lastLogin')).utcOffset(-5).format();
  const tokenAuth = getItem('token');
  const { permissions, refreshPermissions } = usePermissions();
  const [isLoading, setIsLoading] = useState(true);
  const [cachedPermissions, setCachedPermissions] = useState(null);

  const userId = getItem('userId'); // Asume que tienes un userId disponible para identificar al usuario

  const canAccess = (groupName, position) => {
    const group = (cachedPermissions || permissions).find(group => group.name === groupName);
    return group && group.values[position];
  };

  const lastLogin = moment(lastLoginOrigin).fromNow();
  const singOff = () => {
    clearSession();
    navigate('/');
  };

  useEffect(() => {
    const loadPermissions = async () => {
      const storedPermissions = JSON.parse(localStorage.getItem('permissions'));
      const storedUserId = localStorage.getItem('userId');

      if (storedPermissions && storedUserId === userId) {
        setCachedPermissions(storedPermissions);
        setIsLoading(false);
      } else {
        await refreshPermissions();
        setIsLoading(false);
      }
    };

    loadPermissions();
  }, [userId, refreshPermissions]);

  useEffect(() => {
    if (permissions.length > 0) {
      localStorage.setItem('permissions', JSON.stringify(permissions));
      localStorage.setItem('userId', userId);
      setCachedPermissions(permissions);
    }
  }, [permissions, userId]);

  if (isLoading) {
    return <div>Cargando...</div>; // O un spinner de carga
  }

  return (
    <Container>
      <Row>
        <Col className="align-self-end d-flex align-self-center">
          <img src={profile} alt="" className="img-50" />
        </Col>
        <Col>
        </Col>
      </Row>
      <hr className='border-3' />
      <Row>
        <Col className='containerCard mb-5' md={4}>
          <CardProfile
            avatar={avatar}
            userName={name}
            email={email}
            lastLoginDate={lastLoginDate}
            lastLogin={lastLogin}
            singOff={() => singOff()}
          />
        </Col>
        <Col md={8} sm={12}>
          <Row>
            {cachedPermissions && canAccess('DASH', 0) && (
              <Col md={6} className='mb-3'>
                <CardComponent
                  title={'Parametrización'}
                  subtitle={'Sin pendientes'}
                  link={`https://parametrizacion.maximfishing.solutions/auth/${tokenAuth}`}
                />
              </Col>
            )}
            {cachedPermissions && canAccess('DASH', 1) && (
              <Col md={6} className='mb-3'>
                <CardComponent
                  title={'CV TOOLS'}
                  subtitle={'Sin pendientes'}
                  link={`https://cvtools.maximfishing.solutions/auth/${tokenAuth}`}
                />
              </Col>
            )}
            {cachedPermissions && canAccess('DASH', 2) && (
              <Col md={6} className='mb-3'>
                <CardComponent
                  title={'GEEM'}
                  subtitle={'Sin pendientes'}
                  link={`https://geem.maximfishing.solutions/auth/${tokenAuth}`}
                />
              </Col>
            )}
            {cachedPermissions && canAccess('DASH', 3) && (
              <Col md={6} className='mb-3'>
                <CardComponent
                  title={'GPORTAL'}
                  subtitle={'Sin pendientes'}
                  link={`https://gportals.maximfishing.solutions/auth/${tokenAuth}`}
                />
              </Col>
            )}
            {cachedPermissions && canAccess('DASH', 5) && (
              <Col md={6} className='mb-3'>
                <CardComponent
                  title={'INDICADORES'}
                  subtitle={'Sin pendientes'}
                  link={`https://queries.maximfishing.solutions/auth/${tokenAuth}`}
                />
              </Col>
            )}
            {cachedPermissions && canAccess('DASH', 4) && (
              <Col md={6} className='mb-3'>
                <CardComponent
                  title={'SIMAF'}
                  subtitle={'Sin pendientes'}
                  link={`https://simaf.maximfishing.solutions/auth/${tokenAuth}`}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
