import React, { createContext, useContext, useState, useEffect } from 'react';
import API from '../api';
import { getItem, setItem, clearSession } from '../utils';

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const [permissions, setPermissions] = useState([]);
  
  const fetchData = async () => {
    try {
      if(getItem("userId")){
        const response = await API.Auth.getDataPermission(getItem("userId"));
       // console.log("response", response.result);
        if(response.ok) {
          setPermissions(response.result || []);
        } else {
          setPermissions([]);
        }
      }
    } catch (error) {
      console.error('Error de conexion permisos:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [getItem("userId")]);

  const refreshPermissions = () => {
    fetchData();
  };

  return (
    <PermissionsContext.Provider value={{ permissions, refreshPermissions }}>
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionsContext);
